import FacebookWidget from '@/components/widgets/facebook/index.vue';
import GoogleWidget from '@/components/widgets/google/index.vue';
import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";

export default {
  name: "login",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        email: '',
        password: '',
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    }
  },
  computed:{
    ...mapGetters({
      isAuth: `auth/isAuthenticated`
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;

    },
  },
  components: {
    FacebookWidget,
    GoogleWidget,
  },
  methods: {
    ...mapMutations({
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
      changePopupLogin: `auth/CHANGE_POPUP_LOGIN`,
      changePopupForgotPassword: `auth/CHANGE_POPUP_FORGOT_PASSWORD`,
    }),
    ...mapActions({
      singIn: `auth/GET_TOKEN`,
      fetchUser: `profile/FETCH_USER_DATA`,
      fetchFavouriteList: `favorites/GET_FAVORITES_LIST`,
    }),
    goToRegister() {
      this.changePopupLogin(false)
      this.changePopupRegister(true)
    },
    goToChangePassword(){
      this.changePopupLogin(false)
      this.changePopupForgotPassword(true)
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.singIn(this.payload).then(() => {
          this.$toasted.success(this.$t('successAuth'));
          this.changePopupLogin(false)
          this.fetchFavouriteList()
          // this.$router.push({name:'profile'}).catch(()=>{
          //   console.log()
          // })
          this.fetchUser();
        }).catch(error => {
          if (error.response.status === 418) {
            this.$toasted.error(this.$t("authFail"));
          }
          if (error.response.status === 422){
            this.$toasted.error(this.$t("authFailData"));
          }
        })
      }
    }
  }
}